.sol-box-decoration {
  .sol-clearfix;
  border-radius: @border-radius-normal;
  background-color: @box-decoration-bg;
  border:1px solid shade(@box-decoration-bg,5%);
  //box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  margin-bottom: 20px;
  padding: 30px;
  @media @md {
    padding: 20px;
  }
  &-header {
    display: block;
    //padding: 20px;
    //background-color: @box-decoration-header-bg;
    //border-top-left-radius: 4px;
    //border-top-right-radius: 4px;
    color: @box-decoration-header-text;
    text-align: left;
    text-decoration: none;
    line-height: 26px;
    font-weight: 500;
    font-size: 22px;
    margin: 0 0 15px 0;
    a & {
      .focus-outline;
    }
  }
  .sv-text-portlet.sol-btn-small .font-btn {
    margin-bottom: 5px;
  }
  &.sol-box-decoration-alt {
    background-color: @box-decoration-alt-bg;
    border: 3px solid @box-decoration-alt-border;
    &::before {
      .icon(@fa-var-info-circle, 32px, block);
      color: @box-decoration-alt-icon-color;
      position: absolute;
    }
    & > div {
      margin-left: 45px;
    }
  }
}

.sol-divider-decoration {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid @divider-decoration;
}

.sol-simple-box-decoration {
  margin-top: 25px;
  padding: 30px 0;
  border-top: 4px solid @simple-box-decoration-border;
  //border-radius: 3px;
  background-color: @simple-box-decoration-bg;
  &.sol-simple-box-decoration-alt {
    background-color: @simple-box-alt-bg;
  }
  & > div {
    display: block;
    margin-bottom: 5px;
  }
}
.sv-editing-mode {
  .sol-error-box,
  .sol-warning-box {
    display: block;
  }
}
.sol-error-box,
.sol-warning-box {
  display: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.sol-warning-box {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.sol-error-box {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.sol-grid-box-decoration {
  background-color: #706E5F;
  margin-bottom: 20px;

  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 200px;
  }

  p {
    margin-bottom: 0 !important;
    color: white;
  }

  &.sol-grid-box-decoration-alt {
    background-color: #C5C4BE;
  
    p {
      color: black;
    }
  }
}
