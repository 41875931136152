.button() {
  .focus-outline;

  position: relative;
  display: inline-block;
  padding: 10px 25px;
  font-family: @heading-font;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: @button-text;
  text-decoration: none;
  cursor: pointer;
  background-color: @button-bg;
  border: 0;
  border-radius: @button-border-radius;
  box-shadow: inset 0 -2px 0 0 @button-box-shadow;
  .transition(~  'background-color, color');

  &:hover {
    color: @button-text-hover;
    background-color: @button-bg-hover;
  }

  &:active {
    top: 1px;
    box-shadow: inset 0 -1px 0 0 @button-box-shadow;
  }

  & i.fal {
    font-weight: 400;
  }
}
.button-small() {
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 400;
  line-height: 10px;
  color: @button-small-text;
  text-transform: none;
  background-color: @button-small-bg;
  box-shadow: none;

  &:hover {
    color: @button-small-text-hover;
    background-color: @button-small-bg-hover;
  }

  &:active {
    top: 0;
    box-shadow: none;
  }

  [data-whatinput='keyboard'] &:focus {
    z-index: 10;
    outline: 2px solid @white;
    outline-offset: 0;
    box-shadow: 0 0 0 4px @focus-border;
  }
}
