.sol-article-filter {
  display: flex;
  margin-bottom: 20px;

  @media @sm {
    display: block;
  }

  &__text {
    padding-top: 13px;
    padding-right: 10px;
    font-size: 19px;

    @media @sm {
      margin-bottom: 10px;
    }

    & i.fa,
    & i.far,
    & i.fal,
    & i.fab,
    & i.fas {
      margin-right: 5px;
      font-size: 22px;
    }
  }

  &__select {
    display: flex;
    flex: 1;

    @media @sm {
      display: block;
    }
  }

  select {
    .input-select;

    min-height: 40px;
    margin-right: 10px;
    padding: 0 18px;
    color: @button-text;
    line-height: 36px;
    background-color: @button-bg;
    background-image: url('@{sol-icons-path}/form/chevron-down-neg.svg');
    background-position: right 15px top 13px;
    border-color: @button-border;
    border-radius: @border-radius-normal;

    @media @sm {
      margin: 0 0 10px;
    }

    &:hover,
    &:focus {
      color: @button-text;
      background-color: @button-bg-hover;
    }
  }

  .sol-clear-filter {
    .btn-smaller;

    flex: 0 0 40px;
    padding: 0;

    @media @sm {
      display: block;
      width: 100%;
    }

    & i.fa,
    & i.far,
    & i.fal,
    & i.fab,
    & i.fas {
      margin-right: 0;
      font-size: 19px;
    }
  }
}

.sol-clear-filter {
  &.font-btn {
    .no-appearance;

    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 10px 25px;
    padding-bottom: 12px;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    line-height: 22px;
    text-decoration: none;
    background-color: @primary-color;
    border: 0;
    border-radius: 20px;
    box-shadow: inset 0 -2px 0 0 transparent;
    cursor: pointer;
    transition-timing-function: ease;
    transition-duration: 200ms;
    transition-property: background-color, color;
  }
}

.sol-show-more {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;

  button {
    .btn;

    font-weight: 400;
    font-size: 18px;
    border-radius: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
}
