.sol-widget-decoration {
  overflow: hidden;
  text-decoration: none;
  background-color: @widget-bg;
  border-radius: @widget-border-radius;
  box-shadow: 0 2px 2px fade(@black, 5%);

  .sv-mfpimage-portlet img {
    position: relative;
    max-width: 100% !important;
    height: 170px;
    background-position: 50% 50%;
    background-size: cover;
    object-fit: cover;

    @media @md {
      height: 130px;
    }

    @media @md {
      height: 210px;
    }

    @media @sm {
      height: 180px;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: @widget-hover-color;
      opacity: 0;
      .transition(opacity);
    }
  }

  & &-img {
    position: relative;
    max-width: 100% !important;
    height: 170px;
    background-position: 50% 50%;
    background-size: cover;

    @media @md {
      height: 130px;
    }

    @media @md {
      height: 210px;
    }

    @media @sm {
      height: 180px;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: @widget-hover-color;
      opacity: 0;
      .transition(opacity);
    }
  }

  & .sv-text-portlet {
    position: relative;
    max-height: 133px;
    padding: 10px 20px 15px;
    font-size: 13px;
    line-height: 20px;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 55px;
      content: '';
      background-image: linear-gradient(180deg, rgb(255 255 255 / 0%), #fff 85%);
    }
  }

  & .font-widget-heading {
    display: block;
    margin-bottom: 3px;
    font-family: @heading-font;
    font-size: 18px;
    font-weight: 400;
    color: @widget-heading-text;
    text-decoration: none;
    .transition(color);

    a {
      font-size: 16px;
      text-decoration: none;
    
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }

  & .font-normal {
    margin-bottom: 0;

    //font-size: 15px;
    line-height: 1.4em;
  }

  &:hover {
    .sol-widget-decoration-img::before {
      opacity: 0.5;
    }

    .font-widget-heading {
      color: @widget-heading-text-hover;
      text-decoration: underline;
    }
  }

  [data-whatinput="keyboard"] &:focus-within {
    outline: 2px solid @white;
    z-index: 10;
    outline-offset: 0;
    box-shadow: 0 0 0 4px @focus-border !important;
  }
}
