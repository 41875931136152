.sol-mega-menu {
    width: 325px;
    min-height: 400px;
    ul {
        padding-left: 0px;
    }

    & > ul > li {
        list-style: none;
        height: 50px;
        margin-left: 0px;
    }
    &-topitem {
        text-decoration: none;
        position: relative;
        display: flex;
        vertical-align: middle;
        z-index: 1;
        &:hover {
            background-color: darken(@modal-menu-bg,3%);
        }
        &__name {
            font-size: 20px;
            color: @modal-menu-color;
            padding: 10px 0px;
            padding-left: 30px;
        }
        &--expanded {
            font-weight: 500;
        }
        &--active {
            font-weight: 500;
            &[aria-expanded="false"] {
                background-color: shade(@modal-menu-bg,20%);
            }            
        }
        
    }
    &-item {
        text-decoration: none;
        &[data-level="2"] {
            & >span {
            font-weight: 700;
            padding-bottom: 5px;
            border-bottom: 3px solid @bg-gray;
            display: block;
        }
        &[aria-current="page"] > span {
            font-weight: 700;
        }
    }
        &[data-level="3"]{
            > span {
            font-weight: 300;
        }
        &[aria-current="page"] > span {
            font-weight: 500;
        }
    } 
        > span {
            line-height: 25px;
        }
        :hover {
            text-decoration: underline;
        }
        &--active {     
            &[aria-current="page"] > span {
                text-decoration: underline;
                color: @primary-color;
                &::before {
                    .icon(@fa-var-angle-right, inherit, inline-block);
                    .fal;
                    margin-right: 5px;
                }
            }
        }
    }
    &-items {
        position: absolute;
        background: @white;
        left: 0px;
        top: 0px;
        width: 85vw;
        height: ~'calc(100vh - 132px)';
        overflow-y: scroll;
        @media only screen and (min-width: 1900px) {
            width: 70vw;
        }
        @media only screen and (max-width: 1900px) {
            width: 90vw;
        }
        @media only screen and (max-width: 1450px) {
            width: 90vw;
        }
        @media only screen and (max-width: 1200px) {
            width: 85vw;
        }
        & > a {
            display: none;
            position: absolute;
            left: 365px;
            top: 30px;
            z-index: 1;
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
            padding: 5px 10px;
            border:1px solid darken(@bg-gray,5%);
            background-color: @bg-gray;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            &:hover {
                text-decoration: none;
                background-color: darken(@bg-gray,5%);
            }
        }
        & > ul {
            padding-top: 20px; // 70px;
            padding-bottom: 60px;
            min-height: 600px;
            border-left: 325px solid @modal-menu-bg;          
            column-count: 3;
            @media only screen and (max-width: 1450px) {
                column-count: 2;
            }

            li {
                padding-left: 40px;
                padding-right: 20px; 
                margin-top: 10px;
                margin-bottom: 30px;
                list-style: none;
                width: 100%;
                display: inline-block;
            }
        }
    }
    &-sublist > ul {
        padding-left: 0px !important;
        //margin-bottom: 25px;
        li {
            width: 100%;
            margin-bottom: 2px;
            list-style: none;
            font-weight: 300;
            height: auto;
            padding-left: 0;

        }
        
    }
    
    &__button, {
        background: @modal-menu-bg;
        border: none;
        width: 100%;
        //padding-left: 0px;
        padding-left: 30px;
        //position: absolute;
        //left: 290px;   
        z-index: 1;
        position: relative;
        display: flex;
        vertical-align: middle;
        //font-size: 19px;
        //color: @white;
        cursor: pointer;
        &:hover {
            background-color: darken(@modal-menu-bg,3%); 
        }
        &[aria-expanded="true"] {
            background-color: darken(@modal-menu-bg,8%);

        }
        .sol-mega-menu-topitem {
            background-color: @modal-menu-bg;
            &__name {
                .font-normal;
                float: left;
                font-size: 20px;
                font-weight: 300;
                line-height: 22px;
                margin: 0px;
                padding: 10px 0px;
                color: @modal-menu-color;
                font-family: @main-font;
                
            }
            &--expanded {
                font-weight: 500;
            }
            &--active {
                font-weight: 500;
            }
        }
        &.sol-mega-menu-topitem {
            &--expanded {
                background-color: @white;
                span {
                    font-weight: 500;
                    color: @black;
                }
                i {
                    color: @black;
                    color: @white;
                }
            }
            &--active span.sol-mega-menu-topitem__name {
                font-weight: 500;
               
            }
        }
        i {
            right: 0px;
            line-height: 22px;
            padding: 10px 20px;
            position: absolute;
            font-size: 20px;
            color: @modal-menu-color;
        }
    }
    &-expanded {
        height: 900px;
    }
    
}
