.sol-event-search__filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media @sm {
    flex-direction: column;
  }

  > div {
    flex-basis: 48%;

    @media @md-strict {
      flex-basis: 46%;
    }

    @media @sm {
      flex-basis: 100%;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .sol-event-search-collapsible,
  .sol-event-category-collapsible {
    position: relative;

    @media @md {
      width: 100%;
    }

    @media @sm {
      padding-right: 4%;
      padding-left: 4%;
    }

    &__button {
      position: relative;
      z-index: 4;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 40px;
      padding: 0 18px;
      overflow: visible;
      color: @button-text;
      line-height: 36px;
      text-align: left;
      background: 0;
      background-color: @button-bg;
      border: 0;
      border-radius: 20px;
      box-shadow: 0 0 20px 0 rgb(0 0 0 / 5%);
      cursor: pointer;
      transition-timing-function: ease;
      transition-duration: 200ms;
      transition-property: background-color;
      appearance: none;
      appearance: none;
      webkit-appearance: none;

      &__text {
        font-size: 18px;
        font-family: @main-font;
      }

      &__btn {
        position: absolute;
        top: 50%;
        right: 4px;
        display: block;
        width: 40px;
        height: 40px;
        transform: translateY(-50%);
        transform: translateY(-50%);
        transform: translateY(-50%);
        transform: translateY(-50%);
        transform: translateY(-50%);

        &::before,
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 22px;
          height: 4px;
          margin-top: -2px;
          margin-left: -11px;
          background-color: @white;
          border-radius: 2px;
          transition-timing-function: ease;
          transition-duration: 200ms;
          transition-property: all;
          content: '';
        }

        &::after {
          transform: rotate(90deg);
        }
      }
    }

    &__content {
      position: absolute;
      top: 40px;
      left: 50%;
      width: 95%;
      max-height: 0;
      padding: 0 13px;
      overflow: hidden;
      background-color: @white;
      box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
      transform: translateX(-50%);
      transform: translateX(-50%);
      transform: translateX(-50%);
      transform: translateX(-50%);
      transform: translateX(-50%);
      transition-duration: 200ms;
      transition-property: padding, max-height;

      @media @sm {
        width: 90%;
      }

      .sol-article-filter__date {
        label {
          margin-top: 11px;
          color: @black;
          font-weight: bold;
          font-size: 14px;
          line-height: 1;
        }

        .el-date-editor {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;

          i {
            display: none;
          }

          &:first-child {
            flex-basis: 100%;
            max-width: 100%;
          }
        }

        .sol-form-btn {
          &__container {
            flex-basis: 100%;
            width: 100%;

            .sol-btn {
              width: 100%;
            }
          }
        }
      }

      &__dates {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;

        > div {
          display: inline-flex;
          flex-basis: 47%;
          flex-direction: column;
          max-width: 47%;
        }
      }
    }

    &--expanded {
      .sol-event-search-collapsible__content,
      .sol-event-category-collapsible__content {
        z-index: 2;
        max-height: 1000px !important;
        padding: 31px 13px 22px;
        overflow: visible;
      }

      .sol-event-search-collapsible__button,
      .sol-event-category-collapsible__button {
        color: @white;
        background-color: @color-2;

        &__btn {
          &::after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

.sol-event-search__filter {
  @media @sm {
    z-index: 10;
  }
}

.sol-event-search__categories__content {
  > ul {
    padding-left: 12px !important;
  }

  input[type='checkbox'],
  & input[type='radio'] {
    display: none;

    & + label::before {
      position: relative;
      top: -1px;
      display: inline-block;
      float: left;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      text-align: center;
      border: 2px solid @form-input-chk-empty-border;
      border-radius: 3px;
      content: '';
      .transition(~  'color, background-color, border-color, border-width');
    }

    & + label {
      display: block;
      margin-bottom: 10px;
      color: #3b3b3b;
      font-weight: 400;
      font-size: 15px;
      line-height: 1.38;
      cursor: pointer;

      span {
        color: #969696;
        font-size: 16px;
        line-height: 1.38;
      }
    }
  }

  & input[type='checkbox'] {
    &:checked + label::before {
      .icon(@fa-var-check, 12px);

      padding-top: 2px;
      color: @form-input-chk-text;
      background-color: @form-input-chk-bg;
      border-color: @form-input-chk-border;
    }

    & + label {
      &:hover {
        &::before {
          border-color: rgb(110 205 233 / 40%);
        }
      }
    }
  }

  & input[type='radio'] {
    & + label::before {
      border-radius: 50%;
    }

    &:checked + label::before {
      background-color: #204560;
      border-color: #204560;
    }
  }
}

.sol-event-search__filter-n-categories .reset-btn {
  .no-appearance;

  font-family: @main-font;
  cursor: pointer;

  &:hover span {
    text-decoration: underline;
  }
}
