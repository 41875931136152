.sol-action-link {
  .no-appearance;
  .focus-outline;
  display: flex;
  align-items: center;
  min-height: 51px;
  width: 100%;
  background-color: @white;
  color: @black;
  padding: 9px 10px 10px 60px;
  border: 1px solid @gray-3;
  border-radius: @border-radius-normal;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline;
  }
  p {
    max-width: 100%;
    margin-top: 0;
    line-height: 24px;
  }
  .fa-angle-right {
    position: absolute;
    background-color: @button-bg;
    color: @white;
    font-size: 30px;
    height: 100%;
    width: 45px;
    top: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    padding-top: 9px;
  }
}
