.sol-event-listing-page {
  max-width: 760px;
  margin-right: auto;
  margin-left: auto;

  @media @md-strict {
    width: 79%;
  }

  @media @sm {
    max-width: 100%;
  }

  .font-heading-1 {
    @media @sm {
      margin-right: 4%;
      margin-left: 4%;
    }
  }

  &__highlighted-event {
    &__list {
      display: flex;
      flex-wrap: wrap;

      @media @md {
        flex-direction: column;
      }

      @media @sm {
        padding-right: 4%;
        padding-left: 4%;
      }
    }

    &__item {
      flex-basis: 32%;
      flex-grow: 0;
      margin-bottom: 25px;
      overflow: hidden;
      text-decoration: none;
      cursor: pointer;
      border-radius: 3px;
      box-shadow: 0 0 4px 0 rgb(0 0 0 / 15%);

      @media @md {
        max-height: 130px;
      }

      @media @sm {
        max-height: 100%;
      }

      &:hover,
      &:active {
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);

        .sol-event-listing-page__highlighted-event__item__content__title {
          text-decoration: underline;
        }
      }

      &:nth-child(3n+2) {
        margin-right: 1.5%;
        margin-left: 1.5%;

        @media @md {
          margin-right: 0;
          margin-left: 0;
        }
      }

      &__image {
        height: 200px;
        background-position: 50% 50%;
        background-size: cover;

        @media @md {
          float: left;
          width: 33%;
          height: 130px;
        }

        @media @sm {
          float: none;
          width: 100%;
          height: 200px;
        }
      }

      &__content {
        min-height: 155px;
        padding: 17px 25px 31px 21px;
        background-color: @white;

        @media @md {
          float: left;
          width: 67%;
        }

        @media @sm {
          float: none;
          width: 100%;
          padding-top: 16px;
        }

        &__date {
          > span {
            margin-bottom: 4px;

            // font-family: @light-font;
            font-size: 17px;
            font-weight: 300;
            line-height: 21px;
            color: #313131;
          }
        }

        &__title {
          font-size: 21px;
          color: #204560;
          text-align: left;
        }
      }
    }
  }
}
