.sol-landing-page {
  &__top {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 90px 15px;
    background-color: @color-1;
    @media @sm {
      padding: 50px 15px;
    }
  }
  &__content {
    background-color: fadeout(@white, 10%);
    max-width: 660px;
    padding: 25px 35px;
    border-radius: 8px;
    margin-right: auto;
    margin-left: auto;
    @media @sm {
      padding: 10px;
    }
    h1.font-heading-1 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
