.clearfix() {
  &::after,
  &::before {
    display: table;
    content: '';
  }

  &::after {
    clear: both;
  }
}
.no-list-style() {
  padding: 0;
  margin: 0;
  list-style: none;
}
.placeholder(@rules) {
  &::input-placeholder {
    @rules();
  }

  &:placeholder {
    @rules();
  }

  &::placeholder {
    @rules();
  }

  &:input-placeholder {
    @rules();
  }
}
.no-outline-focus() {
  &:focus {
    outline: none;
    box-shadow: none;
    appearance: none;
  }
}
.outline-focus() {
  &:focus,
  [data-whatinput='keyboard'] &:focus {
    z-index: 10;
    outline: 2px solid @focus-border;
    outline-offset: -2px;
  }

  [data-whatinput='initial'] &:focus,
  [data-whatinput='mouse'] &:focus,
  [data-whatinput='touch'] &:focus {
    z-index: inherit;
    outline: none;
    box-shadow: none;
    appearance: none;
  }
}
.no-appearance() {
  background: none;
  border: none;
  appearance: none;
}
.icon(@icon; @size:inherit; @display:inline-block; @position: none) {
  .fa;

  display: @display;
  font-size: @size;
  .position(@position);
  .position(@position) when (@position =before) {
    &::before {
      content: @icon;
    }
  }
  .position(@position) when (@position =after) {
    &::after {
      content: @icon;
    }
  }
  .position(@position) when (@position =none) {
    content: @icon;
  }
}

.transition( @property; @duration:200ms; @style: ease) {
  transition-timing-function: @style;
  transition-duration: @duration;
  transition-property: @property;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.focus-outline() {
  &:focus,
  [data-whatinput='keyboard'] &:focus {
    z-index: 10;
    outline: 2px solid @white;
    outline-offset: 0;
    box-shadow: 0 0 0 4px @focus-border;
  }

  [data-whatinput='initial'] &:focus,
  [data-whatinput='mouse'] &:focus,
  [data-whatinput='touch'] &:focus {
    outline: none;
    box-shadow: none;
    appearance: none;
  }
}
.rotate(@deg) {
  transform: rotate(@deg);
}
.link-style(@bottom: 1px, @sides: 0, @bg-hover: rgba(110, 205, 233, 0.6), @border-color: rgba(110, 205, 233, 0.6)) {
  position: relative;
  z-index: 1;
  padding-right: 1px;
  padding-left: 1px;
  text-decoration: none;
  background-image: linear-gradient(to top, @bg-hover 50%, transparent 50%);
  background-size: 200% 200%;
  border-bottom: 1px solid @bg-hover;
  transition: background-position 300ms, color 300ms ease, border-color 300ms ease;

  &:hover {
    background-image: linear-gradient(to top, @bg-hover 51%, transparent 50%);
    background-position: 0 100%;
    transition: background-position 300ms, color 300ms ease, border-color 300ms ease;
  }
}

.block-link {
  position: relative;

  & a:first-of-type {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      content: '';
    }

    &:focus,
    .js-focus-visible &.focus-visible {
      z-index: inherit;
      outline: none;
      box-shadow: none;
    }
  }
}
