.sol-startpage-wrapper {
  .sol-top {
    .sv-mfpimage-portlet {
      img {
        height: 385px;
        object-fit: cover;
        max-height: unset !important;
        max-width: unset !important;

        @media @md {
          height: 135px;
        }
      }
    }
  }
}

.sol-startpage-top-images {
  z-index: 1;
  width: 100%;


  &::before {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom;
    margin-bottom: -2px;
    bottom: 0;
    content: "";
    min-height: 85px;
    position: absolute;
    width: calc(100% + 2px);
    z-index: 1;

    background-image: url(/webdav/files/System/resources/graphics/pattern/orsa-pattern.svg);
  }

  &-item {
    height: 385px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    @media @md {
      height: 200px !important;
    }

    @media @sm {
      display: flex !important;
    }
  }

  & .slick-next,
  & .slick-prev {
    position: absolute;
    top: 40%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    color: white;
    font-size: 24px;
    background: rgb(149, 22, 55, 65%);
    cursor: pointer;

    i {
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .slick-next {
    right: 0;
    margin-right: 50px;
  }

  & .slick-prev {
    left: 0;
    z-index: 2;
    margin-left: 50px;
  }

  & .slick-dots {
    margin: auto;
    padding: 10px 10px 5px 10px;
    position: absolute;
    z-index: 2;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;

    & li {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      margin: 0 3px 5px;
      .transition(~'background-color, color', 100ms);

      & button {
        display: none;
      }

      &.slick-active{
        background-color: white;
      }
    }
  }

  &-content {
    display: none;
    &:has(.sv-text-portlet) {
      display: block;
      position: absolute;
      width: 570px;
      left: 10%;
      margin-top: 100px;
      top: auto;
      padding: 20px 30px;
      z-index: 10;
      border-radius: 5px;
      background: rgba(149, 22, 55, 90%);

      div div h1,
      div div p {
        margin-bottom: 0;
        color: @white;
      }

      @media @md {
        display: none;
      }

      & .sol-top-search {
        background-color: @top-content-search-bg;

        @media @sm {
          background-color: @top-content-search-bg-phone;
        }
      }
    }
  }
}
