.sol-icon__clock-small {
  position: relative;
  top: 2px;
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
}

.sol-event-item {
  &--pinned {
    position: relative;
    padding: 5px 30px 5px 0;

    &::after {
      position: absolute;
      top: 5px;
      right: 10px;
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      font-size: 18px;
      font-weight: 600;
      color: @orsa-red;
      content: '\f08d';
      transform: rotate(45deg);
    }
  }

  &--img,
  &--no-img {
    position: relative;
    display: block;
    min-height: 140px;
    padding: 2px 20px 30px 0;
    margin-right: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;

    @media @md {
      //padding-left: 160px;
    }

    @media @sm {
      padding-top: 190px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 0;
      border-bottom: 1px solid #ebebeb;
    }

    &:hover .sol-event-item__img::before {
      background-color: @news-item-img-hover;
    }

    &:hover .sol-event-item__title {
      color: @news-item-text-hover;
      text-decoration: underline;
    }
  }

  &--img {
    padding-left: 190px;

    @media @md {
      margin-left: 16px;
    }

    @media @sm {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      margin-top: 0;
      margin-left: 0;
    }
  }

  &--no-img {
    @media @sm {
      display: flex;
      flex-direction: column;
      padding-left: 4%;
      margin-top: 0;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 180px;
    height: 140px;
    text-decoration: none;
    border-radius: @border-radius-normal;
    object-fit: cover;

    @media @md {
      width: 160px;
    }

    @media @sm {
      width: 100%;
      height: 180px;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      border-radius: @border-radius-normal;
      .transition(background-color);
    }
  }

  &__content {
    @media @sm {
      padding-top: 16px;
    }
  }

  &__date-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: tint(@black, 35%);
  }

  &__title {
    display: block;
    margin-bottom: 8px;
    font-family: @heading-font;
    font-size: 25px;
    font-weight: 300;
    line-height: 32px;
    .transition(color);
  }
}
