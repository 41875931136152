footer {
  .sol-bottom:not(.sol-campaign-footer) {
    overflow: auto;
    z-index: 1;
    padding: 40px 0;
    background-color: @footer-bg;
    background-color: @primary-color;
    background-image: url(/webdav/files/System/resources/graphics/Slipsten-h.svg);
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: auto 86%;
    
    @media @md {
      background-position: 100% 115%;
      background-size: auto 30%;
    }
    
    @media @sm {
        .sv-grid-main.sv-fluid-grid .sv-column-4,
        .sv-grid-main.sv-fluid-grid .sv-column-8 {
          width: 100%;
        }
      }

    & .sv-grid-main .sv-row {
      & > .sv-column-4 {
        padding-right: 30px;
      }
    }

    h3 {
      border-bottom: 2px solid white;
      color: white;
      font-size: 22px;
      margin-bottom: 10px !important;
      margin-top: 50px !important;
    }

    .sol-footer-social-media {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 10px 0;

      li {
        padding: 6px 6px;
        font-size: 35px;

        i {
          transition: 0.2s;
          color: white;
        }

        &:hover {
          i {
            color: darken(@white,10%);
          }
        }
      }
    }

    .font-normal {
      color: white;
      font-size: 18px;

      a[rel="external"] {
        &::after {
          .icon(@fa-var-external-link, inherit, inline-block);
          margin-left: 10px;
          font-size: 12px;
        }
      }

      a {
        color: white;
        font-size: 18px;
        text-decoration: none;
        transition: 0.2s;

        &:hover {
          text-decoration: underline;
          color: white;
        }


      &::before {
        .icon(@fa-var-chevron-right, inherit, inline-block);
        margin: 0 10px 0 0;
      }



      }
    }

    .env-button {
      background-color: white;
      border-radius: 5px;
      color: @primary-color;
      font-size: 18px;
      font-weight: bold;
      padding: 10px 14px;
      margin: 10px 0;
      transition: 0.2s;

      &::before {
        .icon(@fa-var-thumbtack, inherit, inline-block);
        margin: 0 10px 0 0;
      }

      &:hover {
        color: @primary-color;
        background-color: darken(@white,10%);
      }
    }

    &-phone {
     .font-normal a {
      &::before {
        .icon(@fa-var-phone, inherit, inline-block);
        margin: 0 10px 0 0;
      }
     }
    }

    &-mail {
     .font-normal a {
      &::before {
        .icon(@fa-var-envelope, inherit, inline-block);
        margin: 0 10px 0 0;
      }
     }
    }
  }
}
