
.sol-top-search-field {
  position: relative;

  input[type=search],
  input[type=text] {
    .no-outline-focus;
    .no-appearance;
    .transition(border-color);
    width: 100%;
    height: 75px;
    padding: 0 75px 0 25px;
    background-color: @search-top-field-bg;
    color: @search-top-field-text;
    font-family: @main-font;
    font-size: 28px;
    line-height: 34px;
    font-weight: 300;
    border-radius: 0;
    @media @sm {
      height: 60px;
      font-size: 21px;
    }
    @media @xs {
      font-size: 16px;
      border-radius: 0;
    }

    @media only screen and (max-width: 329px) {
      font-size: 11px;
    }

    &:focus {
      border-color: @search-top-field-border-hover;
      box-shadow: inset 0 0px 0px 3px @search-focus-shadow;
      @media @md {
        box-shadow: none;
      }
    }
  }

  button[type=submit],
  button.sol-js-toggle-search {
    .no-appearance;
    .focus-outline;
    .transition(background-color);
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 75px;
    background-color: transparent;
    color: @search-top-button-text;
    color: @black;
    font-family: @main-font, sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    display: block;

    @media @sm {
      width: 60px;
      height: 60px;
    }

    & > i.fal {
      position: relative;
      top: 11px;
      font-size: 25px;
      font-weight: 300;
      width: 60px;
      padding-bottom: 20px;
    }
  }

  & > div:hover {
    input[type=search],
    input[type=text] {
      border-color: @search-top-field-border-hover;
    }

    button {
      background-color: @search-top-button-hover;
      color: @search-top-button-text;
    }
  }
}

header .sol-top {
  .sol-top-search-field {
    position: absolute;
    top: 0;
    left: 80px;
    width: 360px;
    z-index: 9;

    @media @lg {
      width: 280px;
    }

    .transition(width);
    input[type=search],
    input[type=text] {
      height: 50px;
      border: 1px solid @orsa-red;
      border-left: 8px solid @orsa-red;
      padding: 0 0 0 15px;
      font-size: 16px;
      color: @black;
      margin: 15px;
    }

    button[type=submit] {
      background-color: transparent;
      top: 0;
      right: -17px;
      color: @black;
      height: 78px;
    }
  }
}
