footer.sv-layout:has(.sol-campaign-footer) {
  position: relative;
  z-index: 1;

  .sol-bottom {
    min-height: 310px;
    padding: 40px 0;
    background-color: @footer-bg;
    background-color: @primary-color;
    background-image: url(/webdav/files/System/resources/graphics/Slipsten-v.svg), url(/webdav/files/System/resources/graphics/Slipsten-h.svg);
    background-repeat: no-repeat;
    background-position: 0 50%, 100% 50%;
    background-size: 150px 100%;

    @media @lg {
      background-image: url(/webdav/files/System/resources/graphics/Slipsten-h.svg);
      background-position: 100% 50%;
      background-size: 130px 100%;
    }

    @media @sm {
      background-image: none;
    }

    & .sv-grid-main .sv-row {
      & > .sv-column-4 {
        padding-right: 30px;
      }
    }

    .sol-footer-contact,
    .sol-footer-links {
      &-item {
        position: relative;
        display: block;
        padding-left: 30px;
        margin-bottom: 11px;
        color: @footer-text;
        text-decoration: none;

        &--icon-links {
          & a::before {
            .icon(@fa-var-angle-right, 14px, block);

            position: absolute;
            top: 1px;
            left: 0;
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            padding-left: 0;
            margin-right: 10px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            background-color: shade(@footer-bg, 25%);
            border-radius: 50%;
            opacity: 1;
          }
        }

        & a,
        & p {
          display: block;
          margin: 0;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          color: @footer-text;
          text-decoration: none;
        }
        .transition(opacity);

        & i.fal:first-child {
          position: absolute;
          top: 1px;
          left: 0;
          font-size: 17px;
        }
      }

      & a:hover {
        text-decoration: underline;
        opacity: 0.7;
      }
    }

    .sol-social-media {
      .sol-clearfix;

      .sol-social-media-item > a {
        float: left;
        width: 40px;
        height: 40px;
        margin-right: 7px;
        font-size: 21px;
        line-height: 34px;
        color: @footer-text;
        text-align: center;
        border: 2px solid @footer-text;
        border-radius: 50%;
        .transition(opacity);

        &:hover {
          opacity: 0.5;
        }
      }
    }

    & .font-footer-header {
      display: block;
      padding: 10px 30px;
      margin-top: 0;
      font-family: @main-font;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: @button-text;
      text-decoration: none;
      border: shade(@footer-bg, 20%);
      border-radius: @border-radius-large;

      &:hover {
        background: shade(@footer-bg, 30%);
      }

      i {
        margin-right: 5px;
      }

      &.sol-footer-important-link {
        color: @black;
        background: @white;

        &:hover {
          background: @bg-gray;
        }

        i {
          margin-right: 8px;
        }
      }
    }
  }

  a {
    color: @footer-text;
    text-decoration: none;
  }

  .sol-tool-contact {
    cursor: pointer;
  }

  p.font-footer-text {
    margin-bottom: 11px;
    font-family: @main-font;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: @footer-text;

    a:hover {
      color: fadeout(@footer-text, 30%);
      text-decoration: underline;
    }
  }
}
