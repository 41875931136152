.sol-pinboard-creator__registration-form {
  select {
    appearance: menulist;
  }

  .sol-form-group {
    margin-top: 10px;
  }

  &.input-group--text-field.input-group--dirty.input-group--select label,
  .input-group--text-field.input-group--dirty:not(.input-group--textarea) label {
    left: 40px !important;
    transform: translateY(-28px) scale(0.75);
  }

  .input-group--text-field {
    label {
      top: 8px;
      left: 50px !important;
    }
  }

  .menu__activator--active {
    label {
      top: 18px;
    }
  }
  .sol-form;
  .sol-event-form;

  label {
    font-weight: 400;
  }

  .sol-notice__dates {
    .application--wrap {
      padding: 0;
      background-color: #fff;
    }

    &__error-message {
      font-family: @main-font;
      line-height: 22px;
      color: red;
    }
  }

  .sol-authority__error-message {
    color: red;
  }

  .sol-title__error-message {
    color: red;
  }

  .sol-form-group__error-message {
    color: red;
  }

  .sol-files-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    li {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .sol-remove-file-btn {
    padding: 5px;
    background-color: #bee9f5;
    border-radius: 3px;
  }

  .sol-file-uploader__upload-btn {
    .btn;

    margin: 10px 0 0;
  }

  button[type='submit'] {
    .btn;
  }

  .sol-file-uploader input {
    display: none;
  }

  .input-group {
    height: 40px;
    padding: 0;
    margin: 23px 0 18px;

    &__details {
      display: none;
    }
  }

  .sol-pinboard-datePicker {
    max-height: 100px;
  }

  .application--wrap {
    min-height: auto;
  }
}

.sol-collapsible-pinboard {
  margin-bottom: 20px;

  .sv-decoration-content {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 32px;

    a {
      display: block;
      margin-bottom: 15px;
    }

    .sol-pin {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

.sol-pinboard {
  padding-left: 30px;
  line-height: 32px;
  background-color: @tbody-odd;
  border-radius: 8px;

  .sol-pin {
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 30px;

    p {
      margin-bottom: 0;
    }
  }
}
