.sol-startpage-entrances .sv-script-portlet,
.sol-landing-page__listing .sv-script-portlet {
  width: 100%;
}

.sol-landing-large {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;

  &__item {
    //margin-right: 20px;
    position: relative;
    flex: 0 0 23%;
    padding-right: 15px;
    padding-bottom: 13px;
    padding-bottom: 20px;
    padding-left: 33px;
    margin: 0 1%;

    a,
    div {
      text-decoration: none;

      i:not(.fa-external-link) {
        position: absolute;
        top: 4px;
        left: 0;
        width: 22px;
        height: 22px;
        margin-right: 5px;
        font-size: 22px;
        font-weight: 300;
        color: @sol-entrance-icon-color;
        text-align: center;
        background: @sol-entrance-icon-bg;
        border-radius: 50%;

        @media only screen and (max-width: 1070px) {
          left: auto;
        }
      }

      i.fa-external-link {
        font-size: 14px;
      }
    }

    & > div {
      cursor: initial;
    }

    &:hover a .sol-landing-large__header {
      text-decoration: underline;
    }

    @media only screen and (max-width: 1070px) and (min-width: 952px) {
      flex: 31%;
      flex-grow: 0;
      flex-shrink: 1;
      padding-left: 60px;
    }

    @media only screen and (max-width: 952px) {
      flex: 100%;
      flex-grow: 0;
      flex-shrink: 1;
      padding-left: 15px;
      margin-top: 0;
      margin-right: 0;
    }
  }

  &__header {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 3px;
    font-size: 20px;
    font-weight: 500;

    @media only screen and (max-width: 1070px) {
      padding-left: 35px;
    }
  }

  &__text {
    @media @md {
      display: none;
    }
  }
}

.sol-startpage-entrances,
.sol-landing-page__listing {
  margin-top: -6px;
  background: @startpage-entrance;
  background-color: @startpage-entrance;
  background-image:
    url(/webdav/files/System/resources/graphics/Slipsten-v.svg),
    url(/webdav/files/System/resources/graphics/Slipsten-h.svg);
  background-repeat: no-repeat;
  background-position: 0 50%, 100% 50%;
  background-size: 150px 300px;

  @media @lg {
    background-image: url(/webdav/files/System/resources/graphics/Slipsten-h.svg);
    background-position: 100%;
    background-size: 130px 260px;
  }

  @media @md {
    //background-size: 70%;
    //background-image: none;
  }

  .sv-text-portlet { //Hej, vad kan vi hjälpa er med?
    @media @md {
      display: block;
    }

    width: 100%;
    background-color: @color-1;

    &-content {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;

      h2 {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        color: @white;
      }
    }
  }

  .sol-landing-large {
    &__item {
      a,
      a h2,
      div,
      div h2 {
        color: @startpage-entrance-color;
      }
    }

    &__text {
      margin-top: 0;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.45em;
      color: @startpage-entrance-color;
    }
  }
}
