.sol-contact-card {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 20px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);

  & .sv-text-portlet {
    min-height: 51px;
    margin-bottom: 10px;
    border-bottom: 1px solid @gray-2;

    .sv-portlet-toolbar {
      font-weight: 600;
    }
  }

  & h1.font-heading-1 {
    .font-normal;

    font-weight: normal;
  }

  & h2.font-heading-2 {
    margin-top: 30px;
    margin-bottom: 10px;
    color: @h4-text;
    font-weight: 500;
    font-size: 20px;
    font-family: @heading-font;
    line-height: 1.2em;
  }

  & h1.font-heading-1,
  & h2.font-heading-2,
  & h3.font-heading-3,
  & h4.font-heading-4,
  & p.font-normal {
    margin: 0;
  }

  .sv-html-portlet {
    display: none;

    p {
      b {
        font-weight: 700;
      }
    }

    .sv-editing-mode & {
      display: block;
    }
  }

  .pagecontent {
    margin: -20px;
    padding: 20px 20px 10px;
    background-color: #f4f4f4;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  }

  .sv-image-portlet,
  .sv-mfpimage-portlet {
    img {
      width: 115px !important;
    }
  }

  > .sv-text-portlet {
    margin: -20px -20px 20px;
    padding: 20px 20px 10px;
    color: white;
    background: @orsa-red;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);

    h1.font-heading-1 {
      color: white;
    }
  }
}
