.sol-contact,
.sol-related {
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: @related-box-bg;
}

.sol-related {
  &-item {
    position: relative;
    display: inline-block;
    padding: 10px 0;
    line-height: 23px;
    text-decoration: none;
    border-bottom: 1px solid tint(@black, 90%);

    &-name {
      color: @related-box-link-text;
      text-decoration: underline;
      .transition(color);
    }

    &-size {
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
      color: @related-box-link-text;
    }

    & i.fal {
      position: absolute;
      top: 15px;
      width: 17px;
      height: 17px;
      margin-right: 5px;
      font-size: 16px;
      line-height: 15px;
      color: @related-box-icon;
      text-align: center;
      border-radius: 50%;
    }

    &:hover &-name {
      color: @related-box-link-hover;
    }
  }

  div &-item &-item-name {
    display: inline-block;
    margin-left: 20px;
    color: @related-box-text;
    text-decoration: none;
  }

  .sol-ul {
    li {
      border-bottom: 1px solid #e9e9e9;
    }
  }
}

.sol-contact {
  &-item {
    margin-bottom: 20px;

    &:not(:first-child) {
      padding-top: 10px;
      border-top: 2px solid @orsa-red;
    }

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 2px;
      font-size: 20px;
    }

    h4 {
      display: block;
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 18px;
    }

    h5 {
      display: block;
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 16px;
    }

    p,
    p.font-normal,
    dd,
    dl {
      margin-top: 0;
      margin-bottom: 5px;
      margin-left: 0;
      font-size: 17px;
      line-height: 1.2;

      a {
        color: @orsa-red;
        text-decoration: none;

        span {
          text-decoration: underline;
        }

        &:hover span {
          color: @normal-link-hover;
        }
      }

      i.fal,
      i.far,
      i.fas {
        width: 17px;
        margin-right: 5px;
        text-align: center;
      }
    }

    dt {
      margin-top: 15px;
      font-weight: 500;
    }

    .sol-contact-img {
      max-height: 150px;
      margin-bottom: 10px;
    }
  }
}

.sol-related-contact {
  .sol-box-decoration-header {
    padding: 20px 30px;
    margin-bottom: 0;
    color: @button-text;
    background: @button-bg;
    border-top-left-radius: @border-radius-normal;
    border-top-right-radius: @border-radius-normal;
  }

  .sol-simple-box-decoration {
    padding: 20px 30px;
    margin-top: 0;
    background-color: @bg-gray;
    border-top: 0 none;
    border-bottom-right-radius: @border-radius-normal;
    border-bottom-left-radius: @border-radius-normal;

    .sol-related-item {
      i.fal {
        color: @button-bg;
      }
    }
  }
}
