.sol-campaign {
  h1,
  h2,
  h3:not(.sol-blurb__header) {
    margin: 60px 0 10px !important;
  }

  &.sol-subpage-wrapper {
    h1,
    h2,
    h3 {
      margin-top: 0 !important;

      @media @sm {
        margin-top: 20px !important;
      }
    }
  }

  h1 {
    font-size: 34px;

    @media @sm {
      font-size: 32px;
    }
  }

  h2 {
    font-size: 30px;

    @media @sm {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @media @sm {
      font-size: 24px;
    }
  }

  p.font-normal {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;

    @media @sm {
      font-size: 18px;
    }
  }

  p.font-description {
    font-size: 22px;
  }

  .sv-grid-main {
    max-width: 1240px !important;
  }

  &.sol-section {
    max-width: 1000px !important;
  }

  &-grid {
    justify-content: space-around !important;
  }

  .font-btn > a {
    color: white;
    background-color: #527d89;
    box-shadow: var(--env-box-shadow);

    &:hover {
      background-color: #0c4f62;
    }
  }

  .sv-column-4 {
    .sol-blurb {
      margin-right: -4px;
      margin-left: -4px;
    }
  }

  .sv-column-6 {
    .sol-blurb {
      margin-right: -4px;
      margin-left: 0;
    }
  }

  &-grid {
    gap: 6px;
    justify-content: space-between !important;

    .sv-layout {
      height: 300px;
      margin-bottom: 20px;
      overflow: hidden;

      div.sv-image-portlet > img,
      div.sv-mfpimage-portlet > img {
        min-width: 100%;
        min-height: 300px;
        object-fit: cover;
      }

      .sol-grid-box-decoration-alt {
        a {
          color: @black;
        }
      }

      a {
        color: @white;
      }
    }

    & [class*='sv-column-'] {
      padding: 0 !important;
      margin: 6px !important;
      box-shadow: var(--env-box-shadow);
    }

    & [class*='sv-column-8'] {
      flex: 1 0 64% !important;
    }

    & [class*='sv-column-6'] {
      flex: 1 0 48% !important;
    }

    & [class*='sv-column-4'] {
      flex: 1 0 31% !important;
    }
  }

  .sv-image-portlet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sv-portlet-image-caption {
    width: 100%;
    background-color: transparent !important;
  }

  .sol-menu-modal-container,
  .sol-mobile-menu-modal-container {
    background-color: #0c4f62;

    .sol-mega-menu {
      &-items {
        & > ul {
          border-color: #0c4f62;
        }
      }

      &-item {
        &[data-level='2'] > span {
          border-color: #0c4f62;
        }
      }

      &-topitem {
        background-color: #0c4f62;

        &__name {
          color: @white;
        }

        & > i {
          color: @white;
        }

        &:hover {
          background-color: #527d89;
        }

        &--expanded {
          background-color: @white !important;

          span {
            color: @black;
          }
        }

        &--active {
          background-color: #C5C4BE !important;

          span,
          i {
            color: @black;
          }
        }
      }
    }
  }

  .sol-mobile-menu-modal-mask {
    top: 80px !important;
  }

  .sv-se-soleilit-blurb {
    height: 100%;
  }

  .sol-startpage-widgets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin-right: -15px;
    margin-left: -15px;
    background: transparent;

    .pagecontent {
      display: flex;

      @media @md {
        flex-direction: column;
      }

      & > .sv-layout > .sv-layout {
        flex: 1 0 30%;
        margin-right: 2%;
        margin-bottom: 20px;
        margin-left: 2%;
      }
    }

    .sol-widget-decoration {
      position: relative;
      background-color: #527d89 !important;
      border-radius: 0;
      box-shadow: var(--env-box-shadow) !important;

      &:hover {
        .font-widget-heading {
          color: white;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      & a:first-of-type {
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          content: '';
        }
      }

      .sv-mfpimage-portlet,
      .sv-mediaflowVideo {
        margin-bottom: 0 !important;
      }

      & .sol-widget-decoration-img,
      & img {
        min-width: 100% !important;
        min-height: 300px !important;
        border-radius: 0 !important;

        &::before {
          display: none !important;
        }
      }
    }

    .sv-layout h2 {
      margin-top: 0 !important;
    }

    .sv-text-portlet {
      position: inherit;
      padding: 25px !important;
      background-color: #527d89 !important;
      border-top: 0 !important;

      a,
      h2,
      p {
        display: -webkit-box;
        overflow: hidden;
        font-size: 18px !important;
        font-weight: 500;
        line-height: 1.5;
        color: white;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &::after {
        display: none !important;
      }
    }
  }

  &-header {
    background-color: #0c4f62 !important;
    border-bottom: none !important;

    .sol-hamburger-megamenu-wrapper,
    .sol-hamburger-wrapper {
      background-color: #0c4f62;

      .sol-hamburger-megamenu,
      .sol-hamburger {
        span {
          background-color: white;
        }

        &-text {
          color: white;
        }
      }
    }

    &.sol-top .sol-top-logo-container {
      @media @sm {
        padding-top: 18px;
      }
    }

    .sol-tool-nav .sol-tool-nav-item > button,
    .sol-tool-nav .sol-tool-nav-item > a {
      color: white;
    }

    & .sol-top-search-field {
      left: 120px !important;
      width: 220px !important;

      input {
        background-color: #0c4f62;
        border-right: none !important;

        &[type='search'] {
          color: white !important;
        }

        &::placeholder {
          color: white;
        }

        &:focus {
          box-shadow: inset 0 0 0 3px #fff;
        }
      }

      button[type='submit'] {
        height: 75px !important;
        color: white !important;
      }
    }

    // .toggle-search {
    //   width: 320px !important;
    // }
  }

  &-top-menu {
    position: relative;

    @media @sm {
      margin-bottom: 50px;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 200%;
      content: '';
      background-color: #0c4f62;

      @media @sm {
        height: 100%;
      }
    }

    & > div {
      position: relative;
      display: flex;
      justify-content: center;
    }

    ul {
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      width: 1240px;
      padding: 50px 0 30px;
      margin: 0;
      margin-left: 80px !important;

      @media (max-width: 1080px) {
        flex-direction: column;
        padding: 30px 20px 10px !important;
        margin: 0 !important;
      }

      & li {
        display: flex;
        flex-wrap: wrap;
        gap: 15px 10px;
        align-items: center;
        max-width: 340px;
        margin-bottom: 10px;
        .block-link;

        @media (max-width: 1080px) {
          gap: 10px;
          width: 100%;
          max-width: unset;
          margin-bottom: 35px;
        }

        i {
          display: flex;
          flex: 1 0 20%;
          align-items: center;
          justify-content: center;
          max-width: 60px;
          height: 60px;
          font-size: 30px;
          color: #0c4f62;
          background-color: #f3ede9;
          border-radius: 50%;
        }

        span.font-normal {
          display: none;
        }

        a {
          flex: 1 0 65%;
          font-size: 24px;
          color: white;
          text-decoration: none;
          text-transform: uppercase;

          @media @sm {
            font-size: 22px;
          }

          &:hover {
            color: white;
            text-decoration: underline;
          }

          &:focus-visible {
            z-index: 10 !important;
            outline: 2px solid white !important;
          }
        }

        span {
          flex: 1 0 100%;
          font-size: 20px;
          line-height: 1.5;
          color: white;

          @media @sm {
            font-size: 18px;
          }
        }
      }
    }
  }

  .sol-breadcrumbs-wrapper {
    background-color: #527d89 !important;

    li a,
    li span {
      font-size: 16px;
      color: white !important;
    }

    ul li:last-of-type span:last-of-type {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &-top-images {
    .sol-startpage-top-images {
      &::before {
        display: none;
      }
    }

    & .sol-startpage-top-images-item {
      height: 800px !important;

      @media (max-width: 1920px) {
        height: 500px !important;
      }

      @media @md {
        height: 300px !important;
      }
    }
  }

  &-content {
    background-color: white;

    .sv-grid-main {
      z-index: 1;
      padding: 0 0 30px;

      & > .sv-layout {
        & > .sv-text-portlet {
          max-width: 50em;
          margin: auto;

          @media @sm {
            max-width: 100%;
          }
        }
      }
    }

    @media @lg-strict {
      .sv-column-4 {
        max-width: calc(100% / 3) !important;
      }
    }

    @media @md {
      padding: 0 20px;
    }

    .sol-shortcuts {
      margin: 0 0 50px;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 15px;
        margin: 0 auto;
        list-style: none;

        @media @xs {
          justify-content: center;
        }

        li {
          padding-bottom: 10px;
        }
      }

      /* stylelint-disable-next-line no-descending-specificity */
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 150px;
        line-height: 2;
        color: white;
        text-decoration: none;
        background-color: #5b7b8b;
        border-radius: 50%;

        span:first-of-type {
          font-size: 35px;
        }

        &:hover span:not(.far, .fal) {
          text-decoration: underline;
        }
      }
    }

    &-bottom {
      display: flex;

      h2 {
        margin-top: 10px !important;
      }

      img {
        max-width: 100% !important;
        max-height: 100% !important;
      }

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 35%;
        content: '';
        background-color: #0c4f62;

        @media @sm {
          height: 0;
        }
      }
    }
  }

  &-footer {
    background-color: #0c4f62 !important;
    background-image: url('/webdav/files/System/resources/graphics/vatten20.svg'), url('/webdav/files/System/resources/graphics/vatten20.svg') !important;
    background-position: 0 75px !important;
    background-size: 100% !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #fff;
    &.sol-bottom {
      padding: 0 0 100px;
      margin-top: 30px;

      @media @sm {
        margin-top: 20px;
      }

      &::before {
        position: absolute;
        top: -50px;
        width: 100vw;
        height: 50px;
        content: '';
        background-color: #0c4f62;

        @media @sm {
          top: -20px;
          height: 20px;
        }
      }

      @media @sm {
        padding: 40px 0;
      }

      .font-footer-header {
        width: 75%;
        margin: auto;
        text-align: center;
        background-color: #527d89;
        box-shadow: var(--env-box-shadow);

        &:hover {
          background-color: #706e5f !important;
        }

        .sol-external-link-icon {
          position: relative;
          top: -1px;
          margin-left: 3px;
          font-size: 16px;
        }
      }
    }

    .sv-grid-main {
      padding: 0 !important;

      .sv-row .sv-column-4 {
        padding: 0 !important;
        text-align: center;
      }
    }

    a {
      display: flex;
      justify-content: center;

      &:focus-visible {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    & button {
      background-color: #527d89 !important;

      &:hover {
        background-color: #4f6878 !important;
      }
    }

    a {
      &:hover {
        & > div {
          background-color: #4f6878 !important;
        }
      }

      & > div {
        background-color: #527d89 !important;
      }
    }
  }

  a:focus-visible,
  button:focus-visible,
  a:focus-visible > div {
    z-index: 10 !important;
    outline: 2px solid #8a233c;
    outline-offset: 1px;
    box-shadow: 0 0 0 4px white;
  }

  .sol-simple-box-decoration {
    background-color: transparent;
  }

  .sv-form-portlet {
    form {
      input[type='submit'] {
        background-color: #527d89;
      }

      .sv-form-consent {
        padding-left: 0;
        margin-top: 10px;
      }
    }
  }
}


header {
  .sol-campaign .sol-campaign-header {
    background-color: #0c4f62 !important;
    border-bottom: none !important;

    .sol-hamburger-megamenu-wrapper,
    .sol-hamburger-wrapper {
      background-color: #0c4f62;

      .sol-hamburger-megamenu,
      .sol-hamburger {
        span {
          background-color: white;
        }

        &-text {
          color: white;
        }
      }
    }

    &.sol-top .sol-top-logo-container {
      @media @sm {
        padding-top: 18px;
      }
    }

    .sol-tool-nav .sol-tool-nav-item > button,
    .sol-tool-nav .sol-tool-nav-item > a {
      i.fal {
        color: white;
      }
      color: white;
    }

    & .sol-top-search-field {
      left: 120px !important;
      width: 220px !important;

      input {
        background-color: #0c4f62;
        border-right: none !important;
        height: 75px !important;


        &[type='search'] {
          margin: 0;
          color: white !important;
          border: none !important;
          font-size: 20px;
        }

        &::placeholder {
          color: white;
        }

        &:focus {
          box-shadow: inset 0 0 0 3px #fff;
        }
      }

      button[type='submit'] {
        color: white !important;
      }
    }
    .sol-mega-menu {
      &__button {
        background: #0c4f62 !important;
      }
      &-items > ul {
        border-color: #0c4f62 !important;
      }
    }
  }
  &:has(.sol-campaign) +div[role='navigation'] {
    display: none;
  }
}

