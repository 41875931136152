.sol-event-info {
  .sol-simple-box-decoration {
    margin-top: 0;
    padding-top: 0;
    background: @bg-gray;
    margin-bottom: 20px;
    border-radius: @border-radius-normal;
    padding: 15px 20px;
    border: 0;
  }
  &-field {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    & > span {
      margin-right: 10px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.sol-event-info-small {
  .sol-clearfix;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px 20px;
  border-radius: @border-radius-normal;
  //border-top: 3px solid @simple-box-decoration-border;
  //border-bottom: 3px solid @simple-box-decoration-border;
  background: @orsa-red;
  color: @white;
  &-item {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    float: left;
    position: relative;
      margin-left: 25px;
    & i {
      margin-right: 6px;
      color: @event-item-icon-bottom;
    }
    &.date {
      &::before {
          .icon(@fa-var-calendar, 16px, block);
          .fal;
          width: 20px;
          height: 20px;
          padding-left: 0;
          line-height: 20px;
          text-align: center;
          font-size: 14px;
          position: absolute;
          left: -25px;
          top: -2px;
          box-sizing: border-box;    
    }
  }
    &.time {
      &::before {
        .icon(@fa-var-clock, 16px, block);
        .fal;
        width: 20px;
        height: 20px;
        padding-left: 0;
        line-height: 20px;
        text-align: center;
        font-size: 16px;
        position: absolute;
        left: -25px;
        top: -2px;
        box-sizing: border-box;    
  }
    }

    &.place {
      &::before {
        .icon(@fa-var-map-marker, 16px, block);
        .fal;
        width: 20px;
        height: 20px;
        padding-left: 0;
        line-height: 20px;
        text-align: center;
        font-size: 16px;
        position: absolute;
        left: -25px;
        top: -2px;
        box-sizing: border-box;     
      }
    }
 
    &.sol-event-info-small-categories {
      &::before {
        .icon(@fa-var-tag, 16px, block);
        .fal;
        width: 20px;
        height: 20px;
        padding-left: 0;
        line-height: 20px;
        text-align: center;
        font-size: 16px;
        position: absolute;
        left: -25px;
        top: -2px;
        box-sizing: border-box;    
  }
    }
  }
  &-categories span {
    &:after {
      content: '/';
      margin-left: 4px;
    }
    &:last-child:after {
      content: '';
      margin-left: 0;
    }
  }
}
