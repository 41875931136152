.sol-event-page {
  .sol-other-dates {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .sol-flex-cnt {
    display: flex;
  }

  .sol-event__show-all-dates {
    color: #204560;
    font-weight: normal;
    font-size: 17px;
    .link-style;
  }

  @media @sm {
    margin-right: 15px;
    margin-left: 15px;

    .sv-image-portlet,
    .sol-event-info {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  h1.font-heading-1 {
    margin-bottom: 22px;
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
}

.sol-icon {
  &__tipsa {
    max-height: 27.6px;
    margin-right: 12px;

    &::after {
      width: 29.5px;
      height: 27.6px;
    }
  }
}

.sol-event-page {
  &__create-event-btn {
    .button;

    margin-bottom: 20px;

    &:hover {
      text-decoration: underline;
    }

    &__icon {
      position: absolute;
      top: 50%;
      right: 4px;
      display: -webkit-box;
      display: flexbox;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
    }
  }
}
