body {
  overflow-x: hidden;
  font-family: @main-font;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: @general-text;
  background-color: @body-bg;
}

p,
p.font-normal,
.font-normal,
p.font-description,
.font-description {
  a&,
  a {
    color: @normal-link;

    &:hover {
      color: @normal-link-hover;
    }
  }
}

p.font-description {
  a&,
  a {
    color: @desc-link;

    &:hover {
      color: @desc-link-hover;
    }
  }
}

p.font-quote,
.font-quote {
  position: relative;
  padding-left: 55px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: @heading-font;
  font-size: 22px;
  font-weight: 300;
  line-height: 29px;
  color: @quote-text;

  @media @md {
    padding-left: 35px;
    font-size: 20px;
    line-height: 27px;
  }

  @media @sm {
    font-size: 18px;
    line-height: 25px;
  }

  @media @xs {
    padding-left: 30px;
  }

  &::before {
    .icon(@fa-var-quote-right, 20px);

    position: absolute;
    top: 0;
    left: 20px;
    line-height: 35px;
    opacity: 0.3;

    @media @md {
      left: 5px;
      line-height: 33px;
    }

    @media @xs {
      left: 5px;
      line-height: 31px;
    }
  }
}

ol,
ul {
  &.sol-bullet-list,
  &.sol-numbered-list,
  &.sol-bullet-list-campaign,
  &.sol-numererad-list-campaign {
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 15px;
    list-style: none !important;

    & li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 7px;
      list-style: none !important;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        font-weight: 700;
      }
    }

    ul {
      padding-left: 0.4rem;
    }
  }

  &.sol-bullet-linklist {
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 15px;
    list-style: none !important;

    & li {
      position: relative;
      padding-left: 15px;
      margin-bottom: 7px;

      &::before {
        position: absolute;
        top: 6px;
        left: 0;
        display: inline-block;
        font-weight: 700;
      }
    }
  }
}

.sol-bullet-list li::before {
  .icon(@fa-var-circle, 16px);

  margin-top: 9px;
  font-size: 10px;
  color: @list-icon;
}

.sol-bullet-list ul li::before {
  font-size: 8px;
}

.sol-bullet-linklist li::before {
  .icon(@fa-var-angle-right, 16px);

  color: @list-icon;
}

.sol-bullet-list-campaign li::before {
  .icon(@fa-var-circle, 16px);

  margin-top: 8px;
  font-size: 10px;
  color: @orsa-dark-blue;
}

ol,
ul {
  &.sol-numbered-list,
  &.sol-numererad-list-campaign {
    & li {
      padding-left: 20px;
      counter-increment: step-counter;

      &::before {
        top: 0;
        font-size: 14px;
        color: @list-number;
        text-align: center;
        content: counter(step-counter) '.';
      }
    }
  }

  &.sol-numererad-list-campaign li::before {
    line-height: 1.9;
    color: @orsa-dark-blue;
  }
}

.sv-linkicon {
  width: 18px;
  max-width: initial;
  height: 18px;
  max-height: initial;
}

.sv-text-portlet .sv-linkicon {
  display: none;
}
