.icon-size(@height;
@width: @height) {
  max-height: @height;

  &::after {
    width: @width;
    height: @height;
  }

  &.sol-icon-edge {
    width: @width;
    height: @height;

    &::after {
      width: @width * 2;
      height: @height * 2;
      margin-top: -(@height / 2);
      margin-left: -(@width / 2);
      transform: scale(0.5);
    }
  }
}

.sol-icon {
  display: inline-block;

  &::after {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    content: '';
  }
}

.sol-required-asterisk {
  &::after {
    display: inline-block;
    color: red;
    font-size: 14px;
    content: '\002a';
  }
}
